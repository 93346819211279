import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["totalSum", "paymentField", "remaining", "auctionField", "auctionSum"]

  connect() {
    this.calculateAuctionSum();
    this.updateRemaining();
  }

  updateRemaining() {
    let totalSum = parseFloat(this.totalSumTarget.value) || 0;
    let remainingAmount = totalSum;

    remainingAmount -= parseFloat(this.auctionSumTarget.textContent)

    this.paymentFieldTargets.forEach((field) => {
      const value = parseFloat(field.value) || 0;
      remainingAmount -= value;
    });

    this.remainingTarget.textContent = remainingAmount.toFixed(2);
  }

  calculateAuctionSum() {
    let auctionSum = 0;

    this.auctionFieldTargets.forEach((field) => {
      const value = parseFloat(field.value) || 0;
      auctionSum += value;
    });

    this.auctionSumTarget.textContent = auctionSum.toFixed(2);
    this.updateRemaining();
  }
}
